<template>
	<div class="">
		<h1>欢迎{{native}}</h1>
	</div>
</template>

<script>
let native;
let p = navigator.platform;
let u = navigator.userAgent;
let is_android = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
let is_ios = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
if (p == "Win32" || p == "Win64" || p == "MacPPC" || p == "MacIntel" || p == "X11" || p == "Linux i686") {//PC调试环境
  console.log('PC环境--');
  // native = new EmptyNative();
  native = "PC环境--供PC端调试"
}
else {
  if (is_android) {//Android终端
    console.log('Mobile环境--Android移动端');
    // native = new AndroidNative();
    native = "Mobile环境--Android移动端"
  }
  else if (is_ios) {//IOS终端
    console.log('Mobile环境--IOS移动端');
    // native = new IOSNative();
    native = "Mobile环境--IOS移动端"
  }
}
export default {
	data() {
		return {
      native
		};
	},
	created() {

	},
	mounted() {

	},
	methods: {



	},

};
</script>

<style lang="less" scoped>

</style>
